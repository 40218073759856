const ONE_WEEK_IN_SECONDS = 7 * 24 * 60 * 60;
const COOKIE_NAME = 'homepage_variation';

export function getPageCookie() {
  return Number(
    document.cookie
      .split('; ')
      .find(row => row.startsWith(`${COOKIE_NAME}=`))
      ?.split('=')[1] ?? 0,
  );
}

export function setPageCookie(page: number) {
  document.cookie = `${COOKIE_NAME}=${page}; path=/; max-age=${ONE_WEEK_IN_SECONDS}`;
}
