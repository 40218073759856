import { useEffect, useRef } from 'react';

import { getPageCookie, setPageCookie } from './pageCookie';

let isCookieSet = false;

const usePageCookieManager = (noOfPages: number) => {
  const noOfPagesRef = useRef(noOfPages);
  useEffect(() => {
    if (!isCookieSet) {
      const pageCookie = getPageCookie();
      setPageCookie((pageCookie + 1) % noOfPagesRef.current);
      isCookieSet = true;
    }
  }, []);
};

export default usePageCookieManager;
