'use client';
import usePageCookieManager from './usePageCookieManager';

const HomepageVariationCookieManagerClient = ({
  totalVariationCount,
}: {
  totalVariationCount: number;
}) => {
  usePageCookieManager(totalVariationCount);
  return null;
};

export default HomepageVariationCookieManagerClient;
